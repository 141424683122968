import { useMutation } from '@apollo/client'
import { ButtonRow } from '@cb/apricot-react'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import { saveStudentScoreSend } from '../../../appsync/actions/student'
import { saveStudentScoreSendMutation } from '../../../appsync/graphql/student'
import GoToScoreSendsButton from './GoToScoreSendsButton'

function ScoreSendReminderContent({ setShouldCloseModal }) {
  // Get user details
  const {
    userDetails: { personId: studentId },
    educationPeriod: { code: educationPeriod },
  } = useUserSettingsQuery()

  const [updateStudentScoreSend] = useMutation(saveStudentScoreSendMutation, {
    update(cache, response) {
      saveStudentScoreSend({ cache, response, educationPeriod })
    },
    onCompleted: () => setShouldCloseModal(true),
  })

  return (
    <>
      <div className="cb-modal-content">
        <p>
          You&#39;ve previously indicated you didn&#39;t want to select a recipient for your Free
          Score report. If you change your mind you can go to the Free Score Sends page at any time
          to learn more about sending AP score reports and select your recipient.
        </p>
      </div>
      <div className="cb-modal-footer">
        <ButtonRow>
          <button
            type="button"
            className="cb-btn cb-btn-naked"
            onClick={() => {
              updateStudentScoreSend({
                variables: {
                  educationPeriod,
                  studentId,
                  optOut: true,
                },
              })
            }}
          >
            Continue to My AP
          </button>
          <GoToScoreSendsButton
            updateFunc={() => {
              updateStudentScoreSend({
                variables: {
                  educationPeriod,
                  studentId,
                  optOut: true,
                },
              })
            }}
          />
        </ButtonRow>
      </div>
    </>
  )
}

export default ScoreSendReminderContent
