import { useAccordionItem } from '@cb/apricot-react'
import { Icon } from '@cb/apricot-react'
import { profile, numberFormat } from '@myap/ui-library'
import orderBy from 'lodash/orderBy'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import Table from '../apricot/Table'
import styles from './courseaccordion.module.scss'

function TeacherTable({ client, teachers, examOnly, totalStudentsRegistered, testCd, isPreAP }) {
  const { selected } = useAccordionItem()

  // Get user details
  const {
    userDetails: { selectedOrgId: orgId },
    educationPeriod: { code: educationPeriod },
  } = useUserSettingsQuery()

  const { classroomUrl, aproUrl } = profile()
  const sortedTeachers = orderBy(
    teachers,
    t => {
      const [firstName, ...lastName] = t.teacherName.split(' ')
      return `${lastName} ${firstName}`
    },
    ['asc']
  )
  const data = examOnly.studentsEnrolled !== null ? [...sortedTeachers, examOnly] : sortedTeachers
  const showRegistrationInfo = totalStudentsRegistered !== null

  const HeaderLink = ({ title, link }) => (
    <a href={link} className={styles['table-header-link']}>
      <span>{title}</span>
      <Icon name="east" />
    </a>
  )

  const BodyBuilder = () =>
    data.map((d, i) => {
      const {
        studentsEnrolled,
        studentsRegistered,
        studentsActive,
        lastUnitAssessed,
        lastUnitName,
        teacherName,
        teacherId,
      } = d

      return (
        <tr key={teacherId || i}>
          <th scope="row">
            {teacherName ? (
              <a href={`${aproUrl}/students?crse=${testCd}&tch=${teacherId}`}>{teacherName}</a>
            ) : (
              <em>Exam Only</em>
            )}
          </th>
          <td>{numberFormat(studentsEnrolled)}</td>
          {showRegistrationInfo ? <td>{numberFormat(studentsRegistered)}</td> : null}
          <td>{Number.isInteger(studentsActive) ? numberFormat(studentsActive) : '-'}</td>
          {!isPreAP ? (
            <td>
              {lastUnitAssessed && lastUnitName ? `Unit ${lastUnitAssessed}: ${lastUnitName}` : '-'}
            </td>
          ) : null}
        </tr>
      )
    })

  const TIER_HEADER = [
    { title: 'Teachers' },
    {
      title: (
        <HeaderLink
          title="AP Registration & Ordering"
          link={`${aproUrl}/students?crse=${testCd}`}
        />
      ),
      colspan: showRegistrationInfo ? 2 : 1,
    },
    {
      title: !isPreAP ? (
        <HeaderLink
          title="AP Classroom Progress Dashboard"
          link={`${classroomUrl}/${testCd}/dashboard_redirect?educationPeriod=${educationPeriod}&schoolId=${orgId}`}
        />
      ) : (
        <HeaderLink
          title="Pre-AP Classroom Usage Dashboard"
          link={`${classroomUrl}/${testCd}/reports/usage/assessment`}
        />
      ),
      colspan: 2,
    },
  ]

  const HEADER_CLASSROOM = [
    { title: !isPreAP ? 'Students Active' : 'Students Accessed Course' },
    ...(!isPreAP ? [{ title: 'Last Unit Assessed' }] : []),
  ]
  const HEADER_ENROLL = { title: 'Student Enrollments' }
  const HEADER_REG = { title: 'Exam Registrations' }

  if (selected) {
    return data.length ? (
      <Table
        tierHeader={TIER_HEADER}
        header={
          showRegistrationInfo
            ? [HEADER_ENROLL, HEADER_REG, ...HEADER_CLASSROOM]
            : [HEADER_ENROLL, ...HEADER_CLASSROOM]
        }
        tbody={<BodyBuilder />}
      />
    ) : (
      <p>
        <em>No sections have been created for this course.</em>
      </p>
    )
  }

  return null
}

export default TeacherTable
