import { forwardRef, cloneElement } from 'react'
import { useApolloClient } from '@apollo/client'
import { Text, isRequired, isCorrectLength } from '@myap/ui-library'
import { Modal, BlackButton, PrimaryButton } from '@cb/apricot-react'
import useStudentSectionDetailsLazyQuery from '../../hooks/useStudentSectionDetailsLazyQuery'
import { setJoinCourseDetails } from '../../../../appsync/actions/student'
import {
  useAnalyticsStudentEnrollStep1Event,
  useAnalyticsStudentEnrollStep1ErrorEvent,
} from '../../../../hooks/analyticsHooks'
import { CONFIRM, ALREADY_ENROLLED_ERROR } from './constants'

import styles from '../joincourse.module.scss'

const EnterCode = forwardRef(
  ({ updateCode, setDisabled, enrollments, error: submitError }, ref) => {
    const [error, setError] = useState(submitError)
    const validate = value => {
      const transformed = value.toUpperCase().trim()
      const alreadyEnrolled = enrollments.find(
        e => e.joinCode === transformed || e.transferCode === transformed
      )
        ? ALREADY_ENROLLED_ERROR
        : undefined
      const required = isRequired(transformed)
      const correntLength = isCorrectLength(transformed, 6)
      return alreadyEnrolled || correntLength || required
    }

    useEffect(() => {
      if (!error) {
        setError(submitError)
      }
    }, [submitError])

    useEffect(() => {
      setDisabled(error !== undefined)
    }, [error])

    return (
      <div className={styles['enter-code']}>
        <Text
          name="enrollmentCode"
          id="enrollmentCode"
          aria-label="Enter join code"
          required={true}
          hideErrorIndicator={true}
          hideRequiredIndicator={true}
          placeholder="&mdash; &mdash; &mdash; &mdash; &mdash; &mdash;"
          maxLength={6}
          ref={ref}
          error={error}
          onBlur={({ relatedTarget, target }) => {
            const { dismiss } = (relatedTarget && relatedTarget.dataset) || {}
            if (dismiss !== 'modal' && !submitError) {
              setError(validate(target.value))
            }
          }}
          onChange={e => {
            const { value } = e.target
            const error = validate(value)
            updateCode(value.toUpperCase())
            setError(error)
          }}
        />
        <p>
          <strong>If you do not have a join code, ask your teacher.</strong>
        </p>
      </div>
    )
  }
)

const FooterActions = forwardRef(
  ({ enrollmentCode, disabled, setOpen, setError, changeStep }, ref) => {
    const client = useApolloClient()
    const { getStudentSectionDetailsLazyQuery, data, error, called, loading } =
      useStudentSectionDetailsLazyQuery()

    useEffect(() => {
      if (called && !loading) {
        if (error) {
          ref.current.focus()
          setError(error)
        } else {
          setJoinCourseDetails(client, { enrollmentCode, sectionId: data.sectionId })
          changeStep(CONFIRM)
        }
      }
    }, [called, loading, error])

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <BlackButton
          className="cb-margin-right-16"
          disabled={loading}
          onClick={() => setOpen(false)}
        >
          Cancel
        </BlackButton>
        <PrimaryButton
          disabled={disabled || loading}
          loading={loading}
          onClick={async () => {
            await getStudentSectionDetailsLazyQuery({ variables: { enrollmentCode } })
          }}
        >
          Submit
        </PrimaryButton>
      </div>
    )
  }
)

function EnterCodeStep({ changeStep, enrollments }) {
  const [enrollmentCode, updateCode] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState(null)
  const [open, setOpen] = useState(true)
  const useAnimation = true
  const inputRef = useRef(null)
  const body = cloneElement(<EnterCode />, {
    ref: inputRef,
    updateCode,
    enrollments,
    setDisabled,
    error,
  })

  useAnalyticsStudentEnrollStep1Event({ submitError: error, joinCode: enrollmentCode })
  useAnalyticsStudentEnrollStep1ErrorEvent({ submitError: error })

  return (
    <Modal
      title="Enter Join Code to Enroll"
      open={open}
      closeAnimation={useAnimation}
      focusId="enrollmentCode"
      footer={
        <FooterActions
          enrollmentCode={enrollmentCode}
          disabled={disabled}
          setOpen={setOpen}
          setError={setError}
          changeStep={changeStep}
          ref={inputRef}
        />
      }
      shadowRoot
      modalNode
    >
      {body}
    </Modal>
  )
}

export default EnterCodeStep
