import {
  SimpleAccordion,
  SimpleAccordionItem,
  SimpleAccordionHeader,
  SimpleAccordionPanel,
} from '@cb/apricot-react'
import { numberFormat, isEmpty } from '@myap/ui-library'
import orderBy from 'lodash/orderBy'
import CourseAccordion from '../../_common/courseaccordion/CourseAccordion'
import ClassroomLink from '../../_common/actions/ClassroomLink'
import { flagCourseTypes, bodyTitle } from '../../_common/content/utils'
import styles from './courses.module.scss'
import courseAccordionStyles from '../../_common/courseaccordion/courseaccordion.module.scss'

function CourseTitle({
  courseName,
  totalStudentsEnrolled,
  totalStudentsRegistered,
  totalExamsOrdered,
}) {
  return (
    <div className={`${styles['courses-title']}`}>
      <div className={styles['course-name']}>{courseName}</div>
      <div className={`${styles['course-totals']}`}>
        {Number.isInteger(totalStudentsEnrolled) ? (
          <div className={styles.total}>
            <div className={styles.count}>{numberFormat(totalStudentsEnrolled)}</div>
            <div className={styles.description}>
              student
              <br />
              enrollments
            </div>
          </div>
        ) : null}
        {Number.isInteger(totalStudentsRegistered) ? (
          <div className={styles.total}>
            <div className={styles.count}>{numberFormat(totalStudentsRegistered)}</div>
            <div className={styles.description}>
              exam
              <br /> registrations
            </div>
          </div>
        ) : null}
        {Number.isInteger(totalExamsOrdered) ? (
          <div className={styles.total}>
            <div className={styles.count}>{numberFormat(totalExamsOrdered)}</div>
            <div className={styles.description}>
              exams
              <br />
              ordered
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

function CoursePanels({ courses, body }) {
  const { hasPreAP, hasAP, hasCK } = flagCourseTypes(courses)
  const title = bodyTitle({ hasAP, hasPreAP })
  const apCourses = courses.filter(course => course.isAP)
  const ckCourses = courses.filter(course => course.isCK)
  const preApCourses = courses.filter(course => course.isPreAP)

  const panels = []
  if (hasAP) {
    panels.push({
      panelId: 'APCourses',
      title: 'AP Courses',
      body: (
        <CourseAccordion
          courses={orderBy(apCourses, ['courseName'], ['asc'])}
          Body={body}
          Title={CourseTitle}
          accordionId="APCourses"
        />
      ),
    })
  }
  if (hasCK) {
    panels.push({
      panelId: 'CKCourses',
      title: 'Career Kickstart Courses',
      body: (
        <CourseAccordion
          courses={orderBy(ckCourses, ['courseName'], ['asc'])}
          Body={body}
          Title={CourseTitle}
          accordionId="CKCourses"
        />
      ),
    })
  }
  if (hasPreAP) {
    panels.push({
      panelId: 'PreAPCourses',
      title: 'Pre-AP Courses',
      body: (
        <CourseAccordion
          courses={orderBy(preApCourses, ['courseName'], ['asc'])}
          Body={body}
          Title={CourseTitle}
          accordionId="PreAPCourses"
        />
      ),
    })
  }

  return (
    <>
      {!isEmpty(title) ? (
        <h2 className="cb-h5 cb-margin-top-32 cb-margin-bottom-8">
          <ClassroomLink title={`${title} Classroom`} />
        </h2>
      ) : null}
      {panels.length ? (
        <div className={courseAccordionStyles['courses-container']}>
          <SimpleAccordion single={false}>
            {panels.map(({ panelId, title, body }) => (
              <SimpleAccordionItem key={panelId} open={true}>
                <SimpleAccordionHeader>{title}</SimpleAccordionHeader>
                <SimpleAccordionPanel>{body}</SimpleAccordionPanel>
              </SimpleAccordionItem>
            ))}
          </SimpleAccordion>
        </div>
      ) : null}
    </>
  )
}

export default CoursePanels
