import TeacherRaceAndEthnicityModal from '../../teacher/registration'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import useTeacherRegistrationQuery from '../../teacher/hooks/useTeacherRegistrationQuery'

function TeacherDemographics() {
  const [openRegistrationModal, setOpenRegistrationModal] = useState(false)
  // Get user details
  const {
    educationPeriod: { code: educationPeriod },
  } = useUserSettingsQuery()

  // Get teacher registration
  const { data, loading, error: queryError } = useTeacherRegistrationQuery({})

  useEffect(() => {
    // If teacher has not provided R&E or has selected "do not wish to answer" and we have entered
    // a new education period, then present R&E modal
    if (
      !loading &&
      !data?.scrub &&
      data?.lastUpdatedEpc !== educationPeriod &&
      data?.canCollectEthnicities
    ) {
      setOpenRegistrationModal(true)
    }
  }, [loading, data, educationPeriod])

  if (openRegistrationModal) {
    return <TeacherRaceAndEthnicityModal isDismissable={false} />
  }

  return null
}

export default TeacherDemographics
