import { Modal, PrimaryButton, BlackButton, Icon } from '@cb/apricot-react'
import { Error, formatDate } from '@myap/ui-library'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import useStudentUpdateEnrollmentMutation from '../hooks/useStudentUpdateEnrollmentMutation'
import { useAnalyticsStudentPreRegChangeEvent } from '../../../hooks/analyticsHooks'
import { SUCCESS, SELECTED_NO, SELECTED_YES, STATUS_UNDECIDED } from './constants'

import styles from './register.module.scss'

function Register({ courseName, orgName, examStartTime, updateChoice, choice, error }) {
  const isYes = choice === SELECTED_YES
  const srText = <span className="sr-only">selected</span>

  return (
    <div className={styles['register-options']}>
      <p>
        You are registered to take the {courseName}
        {examStartTime ? ` exam in ${formatDate(examStartTime, 'MMM yyyy')}, ` : ' assessment '}
        given by {orgName}.
      </p>
      <div className={styles['register-choice-container']}>
        <button
          type="button"
          className={`cb-hover ${isYes ? styles.selected : null}`}
          onClick={() => updateChoice(SELECTED_YES)}
        >
          {isYes ? <Icon name="check" color="green3" /> : null} Confirm Registration{' '}
          {isYes ? srText : null}
        </button>
        <button
          type="button"
          className={!isYes ? styles.selected : null}
          onClick={() => updateChoice(SELECTED_NO)}
        >
          {!isYes ? <Icon name="x-mark" color="red1" /> : null} No, I wish to remove myself from
          taking this AP {examStartTime ? 'exam' : 'assessment'} {!isYes ? srText : null}
        </button>
      </div>
      {error ? <Error message={error} /> : null}
    </div>
  )
}

function SelectIntent({
  changeStep,
  examIntent,
  examStartTime,
  enrollmentId,
  courseName,
  orgName,
}) {
  const [choice, updateChoice] = useState(
    examIntent === STATUS_UNDECIDED ? SELECTED_YES : examIntent
  )
  const [open, setOpen] = useState(true)
  // Get user details
  const {
    educationPeriod: { code },
  } = useUserSettingsQuery()

  // Update student enrollment mutation
  const {
    mutate: update,
    data: updatedData,
    loading: updating,
    error: updateError,
  } = useStudentUpdateEnrollmentMutation({ educationPeriod: code })

  useAnalyticsStudentPreRegChangeEvent({ examIntent })

  const handleCancelClick = () => setOpen(false)

  useEffect(() => {
    if (Object.keys(updatedData)?.length > 0 && !updateError) {
      changeStep(SUCCESS)
    }
  }, [updatedData, updateError])

  return (
    <Modal
      open={open}
      title={`Register for ${examStartTime ? 'Exam' : 'Assessment'}`}
      shadowRoot
      modalNode
      footer={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <BlackButton
            className="cb-margin-right-16"
            disabled={updating}
            onClick={handleCancelClick}
          >
            Cancel
          </BlackButton>
          <PrimaryButton
            disabled={!choice || updating || choice === examIntent}
            loading={updating}
            onClick={async () => {
              await update({
                variables: { code, input: { enrollmentId, examIntent: choice } },
              })
            }}
          >
            Confirm
          </PrimaryButton>
        </div>
      }
    >
      <Register
        courseName={courseName}
        orgName={orgName}
        examStartTime={examStartTime}
        updateChoice={updateChoice}
        choice={choice}
        error={updateError}
      />
    </Modal>
  )
}

export default SelectIntent
