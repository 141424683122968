import { useApolloClient } from '@apollo/client'
import { SelectOrgDropdown } from '@myap/ui-library'
import { setRole, setOrg } from '../../../appsync/actions/settings'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import styles from './schooltoggle.module.scss'

function SchoolToggle({ preview }) {
  const client = useApolloClient()
  const {
    educationPeriod,
    userDetails: { selectedOrgId, roles },
  } = useUserSettingsQuery()

  return (
    <div className={styles['school-toggle-container']}>
      {preview ? (
        <div className={styles.sample}>Sample School</div>
      ) : (
        <SelectOrgDropdown
          selectedOrgId={selectedOrgId}
          roles={roles}
          action={r => {
            const { role, orgId, orgName } = r || {}
            setRole(client, role)
            setOrg(client, orgId)
          }}
        />
      )}
      <div className={`${styles['education-period']} cb-font-size-small cb-font-weight-bold`}>
        {educationPeriod.descr}
      </div>
    </div>
  )
}

export default SchoolToggle
