import { useId } from 'react'
import { useApolloClient } from '@apollo/client'
import { Formik, Form } from 'formik'
import isBoolean from 'lodash/isBoolean'
import {
  PrimaryButton,
  RadioButtonGroup,
  RadioButton,
  Spinner,
  Icon,
  Toasts,
  Toast,
} from '@cb/apricot-react'
import {
  isEmpty,
  Error,
  SelectSchool,
  SchoolZipFilter,
  SelectGradeLevel,
  EnterStudentId,
  SelectPreferredLanguage,
  ChooseRaceEthnicity,
  ChooseParentalEducation,
} from '@myap/ui-library'
import { STUDENT_RACE_OPTIONS, STUDENT_ETHNICITY_OPTIONS } from '@myap/metadata'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import useStudentInfoAndRegistrationQuery from '../hooks/useStudentInfoAndRegistrationQuery'
import useStudentPrivacyQuery from '../hooks/useStudentPrivacyQuery'
import useStudentUpdateRegistrationMutation from '../hooks/useStudentUpdateRegistrationMutation'
import OpenNewWindow from '../../_common/actions/OpenNewWindow'
import {
  REQUIRED_STUDENT_REGISTRATION_VALUES,
  DEFAULT_STUDENT_REGISTRATION_VALUES,
  FIELD_ZIP,
  FIELD_STREET1,
  FIELD_STREET2,
  FIELD_STREET3,
  FIELD_CITY,
  FIELD_STATE,
  FIELD_POSTALCODE,
  FIELD_PROVINCE,
  FIELD_COUNTRY,
  FIELD_RACE,
  FIELD_ETHNICITY,
  FIELD_RECOGNIZED_TRIBE,
  FIELD_ENROLLED_TRIBE,
  FIELD_DESCENDANT_TRIBE,
  FIELD_SCHOOLNAME,
  FIELD_SCHOOLID,
  FIELD_ORG_CITY,
  FIELD_ORG_STATE,
  FIELD_ORG_COUNTRY,
} from './constants'
import { getShadowRootNode } from '../../../utilities/dom'

import styles from './profileandregform.module.scss'

function RegistrationForm({
  changeStep,
  nextStep,
  setOpen,
  withHeader = true,
  isDismissable = true,
  adjustHeight,
}) {
  const client = useApolloClient()
  const modalHeaderId = useId()
  const [initialValues, setInitialValues] = useState(DEFAULT_STUDENT_REGISTRATION_VALUES)
  const [showToast, setShowToast] = useState(false)
  const [privacy, setPrivacy] = useState({})
  const [updateError, setUpdateError] = useState(null)
  const isStandAlone = typeof changeStep !== 'function'
  let toastTimerId = null

  // Get user details
  const {
    educationPeriod: {
      code: currentEPC,
      hasEnrollments: hasCurrentYearEnrollments,
      isRegistered: hasCurrentYearRegistration,
      descr: currentYearDescr,
    },
    educationPeriods,
  } = useUserSettingsQuery()

  const {
    code: previousEPC,
    hasEnrollments: hasPreviousYearEnrollments = false,
    isRegistered: hasPreviousYearRegistration = false,
    descr: previousYearDescr,
  } = educationPeriods.find(edpd => edpd.chronology === 'previous') ?? {}

  const academicYearDescriptions = {
    ...(hasCurrentYearRegistration && hasCurrentYearEnrollments
      ? { [currentEPC]: currentYearDescr }
      : {}),
    ...(hasPreviousYearRegistration && hasPreviousYearEnrollments
      ? { [previousEPC]: previousYearDescr }
      : {}),
  }

  const code =
    (hasCurrentYearRegistration && hasCurrentYearEnrollments) || !isStandAlone
      ? currentEPC
      : hasPreviousYearRegistration && hasPreviousYearEnrollments
      ? previousEPC
      : null
  const [selectedEPC, setSelectedEPC] = useState(code)
  const refresh = !hasCurrentYearEnrollments && !isStandAlone

  // RE: setting refresh prop to same as ProfileForm, if studentInfoAndRegistrationQuery is same
  // as request made on ProfileForm, data will be fetched from cache first and call to Appsync can
  // be avoided
  const variables = {
    code: selectedEPC,
    isStudent: true,
    refresh,
  }

  // Get student info and registration
  const { loading, data } = useStudentInfoAndRegistrationQuery({
    educationPeriod: selectedEPC,
    refresh,
  })

  // Mutate student registration
  const { mutate: update, loading: saving } = useStudentUpdateRegistrationMutation({
    educationPeriod: selectedEPC,
    refresh,
  })

  useEffect(() => {
    if (!loading && !isEmpty(data) && !isEmpty(privacy) && adjustHeight) {
      adjustHeight()
    }
  }, [data, loading, privacy, adjustHeight])

  useEffect(() => {
    let isCurrent = true
    if (!loading && !isEmpty(data) && isCurrent) {
      const keys = data ? Object.keys(data) : []
      const initialVals = keys.reduce((obj, key) => {
        if (key !== '__typename') {
          if (key === 'address') {
            const addressData = data.address
            // is addressData an object?
            if (typeof addressData === 'object' && addressData !== null) {
              // get all the keys in address
              const addressKeys = Object.keys(addressData)
              return addressKeys.reduce((obj, addrKey) => {
                if (addrKey !== '__typename' && addrKey !== 'city') {
                  return { ...obj, [addrKey]: addressData[addrKey] || '' }
                }
                // We want to rename the city field so it doesn't clash with ORG CITY
                if (addrKey === 'city') {
                  return { ...obj, [FIELD_CITY]: addressData.city || '' }
                }
                return obj
              }, obj)
            } else {
              // address was not an object, drop in default values
              return {
                ...obj,
                [FIELD_STREET1]: '',
                [FIELD_STREET2]: '',
                [FIELD_STREET3]: '',
                [FIELD_CITY]: '',
                [FIELD_STATE]: '',
                [FIELD_POSTALCODE]: '',
                [FIELD_PROVINCE]: '',
                [FIELD_COUNTRY]: '',
              }
            }
          }
          if (key === 'attendingOrg') {
            const attendingOrgData = data.attendingOrg
            if (attendingOrgData !== null) {
              // get all the keys in attendingOrg
              const attendingOrgKeys = Object.keys(attendingOrgData)
              return attendingOrgKeys.reduce((obj, orgKey) => {
                if (orgKey === 'orgName') {
                  return {
                    ...obj,
                    attendingOrgName: attendingOrgData.orgName || '',
                  }
                }
                if (orgKey === 'orgId') {
                  return { ...obj, attendingOrgId: attendingOrgData.orgId || '' }
                }
                // We want to rename the city, stateCd, countryCd fields so they don't clash with fields of same name
                if (orgKey === 'city') {
                  return { ...obj, [FIELD_ORG_CITY]: attendingOrgData.city || '' }
                }
                if (orgKey === 'stateCd') {
                  return { ...obj, [FIELD_ORG_STATE]: attendingOrgData.stateCd || '' }
                }
                if (orgKey === 'countryCd') {
                  return { ...obj, [FIELD_ORG_COUNTRY]: attendingOrgData.countryCd || '' }
                }
                return obj
              }, obj)
            }
            // generate empty data when attendingOrg is null
            return {
              ...obj,
              [FIELD_SCHOOLNAME]: '',
              [FIELD_SCHOOLID]: null,
              [FIELD_ORG_CITY]: '',
              [FIELD_ORG_STATE]: '',
              [FIELD_ORG_COUNTRY]: '',
            }
          }
          // if any remaining value is specifically null, convert to empty string
          return {
            ...obj,
            [key]:
              isBoolean(data[key]) || data[key] !== null || Array.isArray(data[key])
                ? data[key]
                : '',
          }
        }
        return obj
      }, {})
      const { resetOrgInd = false } = initialVals
      setInitialValues({
        ...DEFAULT_STUDENT_REGISTRATION_VALUES,
        ...initialVals,
        ...(resetOrgInd
          ? {
              [FIELD_SCHOOLNAME]: '',
              [FIELD_SCHOOLID]: null,
              [FIELD_ORG_CITY]: '',
              [FIELD_ORG_STATE]: '',
              [FIELD_ORG_COUNTRY]: '',
            }
          : {}),
        // renderKey: Date.now(), // Forces Formik to reinitialize after form has finished loading
      })
    }
    return () => {
      isCurrent = false
    }
  }, [data, loading])

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      validateOnMount={true}
      initialValues={initialValues}
      validate={values => {
        const errors = {}
        if (
          Array.isArray(values[FIELD_RACE]) &&
          values[FIELD_RACE].find(v => v == STUDENT_RACE_OPTIONS.nativeAmerican.value) &&
          values[FIELD_RECOGNIZED_TRIBE] === null
        ) {
          errors[FIELD_RECOGNIZED_TRIBE] = 'Field is required'
        }

        return errors
      }}
      onSubmit={async (values, { resetForm }) => {
        if (saving) {
          return
        }

        setUpdateError(null)

        try {
          const {
            [FIELD_ZIP]: zip,
            [FIELD_RECOGNIZED_TRIBE]: enrolledRecognizedTribeInd,
            [FIELD_ENROLLED_TRIBE]: enrolledTribeCd,
            [FIELD_DESCENDANT_TRIBE]: descendantTribe,
            [FIELD_ORG_CITY]: orgCity,
            [FIELD_ORG_STATE]: orgState,
            [FIELD_ORG_COUNTRY]: orgCountry,
            [FIELD_CITY]: city,
            [FIELD_COUNTRY]: countryCd,
            [FIELD_POSTALCODE]: postalCode,
            [FIELD_PROVINCE]: province,
            [FIELD_STATE]: stateCd,
            [FIELD_STREET1]: streetAddr1,
            [FIELD_STREET2]: streetAddr2,
            [FIELD_STREET3]: streetAddr3,
            [FIELD_RACE]: raceCodes,
            [FIELD_ETHNICITY]: hispanicOriginCodes,
            missingRegInfoInd, // remove
            resetOrgInd, // remove
            __typename,
            ...saveable
          } = values // filter out UI only fields and Native American tribe fields
          // We don't want to include Native American tribe fields if raceCode 1 is not selected
          const tribeFields =
            Array.isArray(values[FIELD_RACE]) &&
            values[FIELD_RACE].find(v => v == STUDENT_RACE_OPTIONS.nativeAmerican.value)
              ? {
                  enrolledRecognizedTribeInd,
                  enrolledTribeCd: enrolledTribeCd !== '' ? enrolledTribeCd : null,
                  descendantTribe,
                }
              : {}
          // reprocess all values with an empty string as null so service doesn't complain
          const processedSaveable = Object.keys(saveable).reduce((acc, key) => {
            if (saveable[key] === '') {
              return { ...acc, [key]: null }
            }
            return { ...acc, [key]: saveable[key] }
          }, {})
          const updateObj = {
            ...processedSaveable,
            ...(!privacy?.hideRaceField ? { raceCodes, ...tribeFields } : { raceCodes: [] }),
            ...(!privacy?.hideEthnicityField
              ? { hispanicOriginCodes }
              : { hispanicOriginCodes: [] }),
            address: {
              streetAddr1,
              streetAddr2,
              streetAddr3,
              city,
              stateCd,
              postalCode,
              province,
              countryCd,
            },
            attendingOrg: {
              orgName: values[FIELD_SCHOOLNAME],
              orgId: values[FIELD_SCHOOLID],
              city: orgCity,
              stateCd: orgState ?? null,
              countryCd: orgCountry,
            },
          }
          // console.log('Registration - Submitting data:', updateObj)
          delete variables.refresh
          await update({
            variables: {
              ...variables,
              input: updateObj,
              isRegistration: true,
              isStudent: true,
            },
          })
          resetForm(values) // reset form so new values are set to initial values
          if (isStandAlone && !isDismissable) {
            setOpen(false)
          } else if (isStandAlone) {
            setShowToast(true)
            toastTimerId = setTimeout(() => setShowToast(false), 5000)
          } else {
            changeStep(nextStep)
          }
        } catch (err) {
          setUpdateError(err?.message)
          // console.error('Error saving student registration', err)
        }
      }}
    >
      {({
        isValid,
        dirty,
        errors,
        isSubmitting,
        submitForm,
        values,
        setFieldValue,
        setTouched,
      }) => {
        // console.log('attendingOrgId:', values[FIELD_SCHOOLID])
        const schoolId = values[FIELD_SCHOOLID] ?? null

        // Get student privacy info
        const { loading: privacyDataLoading, data: privacyData } = useStudentPrivacyQuery({
          orgId: parseInt(schoolId, 10),
          stateCd: values[FIELD_STATE],
        })

        const disableForm = loading || isSubmitting || privacyDataLoading

        // Save off the privacy data response for use in OnSubmit and adjusting modal content height
        useEffect(() => {
          if (!isEmpty(privacyData) && !privacyDataLoading) {
            setPrivacy(privacyData)
          }
        }, [privacyData, privacyDataLoading])

        useEffect(() => {
          if (!isEmpty(updateError)) {
            const errorEl = getShadowRootNode().querySelector('#formError')
            errorEl.scrollIntoView(true)
            errorEl.focus()
          }
        }, [updateError])

        const staticFieldStyles = {
          label: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '16px',
            fontWeight: 400,
            color: '#1e1e1e',
            marginLeft: 0,
          },
          value: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#1e1e1e',
            marginLeft: 0,
          },
        }

        return (
          <>
            {withHeader ? (
              <div className="cb-modal-header cb-modal-has-close">
                <h2 className="cb-modal-title" id={`apricot_modal_header_${modalHeaderId}`}>
                  {isStandAlone ? 'AP Registration' : 'AP Registration - Step 2'}
                </h2>
                {isDismissable ? (
                  <button
                    type="button"
                    className="cb-btn cb-btn-square cb-btn-greyscale cb-btn-close"
                    aria-describedby={`apricot_modal_header_${modalHeaderId}`}
                    data-cb-modal-close="true"
                  >
                    <span />
                    <Icon name="x-mark" />
                    <span className="sr-only">Close Modal</span>
                  </button>
                ) : null}
              </div>
            ) : null}
            <div className="cb-modal-content">
              <Toasts bottom>
                <Toast
                  timeout={5000}
                  type="success"
                  message="AP Registration saved"
                  controlled={true}
                  show={showToast}
                  onDismiss={() => {
                    clearTimeout(toastTimerId)
                    setShowToast(false)
                  }}
                />
              </Toasts>
              {isStandAlone ? (
                <p>
                  Please confirm the information below. If you update the school you attend,
                  you&#39;ll also need to go to your{' '}
                  <OpenNewWindow url="https://my.collegeboard.org/profile/information">
                    College Board account settings
                  </OpenNewWindow>{' '}
                  to make the same change &mdash; changes made to your AP Profile do not
                  automatically carry over to your College Board account. <strong>NOTE:</strong> Be
                  careful when selecting your school as there could be multiple search results of
                  the same name. The city and state are listed next to the school name; you can also
                  filter by zip code.
                </p>
              ) : (
                <p>
                  Please confirm the information below. If you update the school you attend,
                  you&#39;ll also need to go to your{' '}
                  <OpenNewWindow url="https://my.collegeboard.org/profile/information">
                    College Board account settings
                  </OpenNewWindow>{' '}
                  to make the same change &mdash; changes made to your AP Profile do not
                  automatically carry over to your College Board account. <strong>NOTE:</strong> Be
                  careful when selecting your school as there could be multiple search results of
                  the same name. The city and state are listed next to the school name; you can also
                  filter by zip code.
                </p>
              )}
              <Form className={styles.registration} data-loaded={!privacyDataLoading}>
                {!isEmpty(updateError) ? (
                  <div id="formError" tabIndex="-1">
                    <Error title="Error" className="cb-margin-bottom-16">
                      {updateError
                        ? updateError
                        : 'There was an error saving your information. Please try again later.'}
                    </Error>
                  </div>
                ) : null}
                <fieldset style={{ marginBottom: 0 }}>
                  {isStandAlone && !values?.missingRegInfoInd ? (
                    <div style={{ display: 'inline-block' }}>
                      {hasCurrentYearRegistration &&
                      hasCurrentYearEnrollments &&
                      hasPreviousYearRegistration &&
                      hasPreviousYearRegistration &&
                      !values?.missingRegInfoInd ? (
                        <RadioButtonGroup
                          name="yearRadioGroup"
                          legend="School Year"
                          defaultValue={selectedEPC}
                          onChange={value => setSelectedEPC(parseInt(value, 10))}
                        >
                          <RadioButton
                            id="currentYearRadio"
                            label={currentYearDescr}
                            value={currentEPC}
                            disabled={disableForm}
                          />
                          <RadioButton
                            id="previousYearRadio"
                            label={previousYearDescr}
                            value={previousEPC}
                            disabled={disableForm}
                          />
                        </RadioButtonGroup>
                      ) : (
                        <>
                          <div className="cb-margin-top-24" style={staticFieldStyles.label}>
                            School Year
                          </div>
                          <div style={staticFieldStyles.value}>
                            {academicYearDescriptions[selectedEPC]}
                          </div>
                        </>
                      )}
                    </div>
                  ) : null}
                  <div style={{ display: 'inline-block' }}>
                    <Spinner
                      show={loading || privacyDataLoading}
                      size="16"
                      className={
                        isStandAlone && !values?.missingRegInfoInd
                          ? 'cb-margin-left-24'
                          : 'cb-margin-top-16'
                      }
                    />
                  </div>
                  {isStandAlone && !values?.missingRegInfoInd && values?.studentId ? (
                    <>
                      <div className="cb-spacerv-24" />
                      <div style={staticFieldStyles.label}>AP ID</div>
                      <div style={staticFieldStyles.value}>{values?.studentId}</div>
                    </>
                  ) : null}
                  <div className="cb-spacerv-24" />
                  <SelectSchool
                    values={values}
                    errors={errors}
                    dirty={dirty}
                    setFieldValue={setFieldValue}
                    isCurrentYear={true}
                    disabled={loading || isSubmitting}
                    shadowRoot
                  />
                  <div className="cb-spacerv-24" />
                  <SchoolZipFilter />
                  {(hasCurrentYearRegistration &&
                    hasCurrentYearEnrollments &&
                    selectedEPC === currentEPC) ||
                  !isStandAlone ? (
                    <>
                      <div className="cb-spacerv-24" />
                      <SelectGradeLevel
                        isCurrentYear={true}
                        setFieldValue={setFieldValue}
                        disabled={disableForm}
                      />
                      <div className="cb-spacerv-24" />
                      <EnterStudentId isCurrentYear={true} disabled={disableForm} />
                      <div className="cb-spacerv-24" />
                      <SelectPreferredLanguage
                        isCurrentYear={true}
                        setFieldValue={setFieldValue}
                        disabled={disableForm}
                      />
                      <ChooseRaceEthnicity
                        values={values}
                        initialValues={initialValues}
                        disabled={disableForm}
                        isSubmitting={isSubmitting}
                        client={client}
                        setFieldValue={setFieldValue}
                        setTouched={setTouched}
                        isCurrentYear={true}
                        ethnicityOptions={STUDENT_ETHNICITY_OPTIONS}
                        raceOptions={STUDENT_RACE_OPTIONS}
                        requiredValues={REQUIRED_STUDENT_REGISTRATION_VALUES}
                        privacy={privacy}
                        privacyDataLoading={privacyDataLoading}
                      />
                      <ChooseParentalEducation
                        disabled={disableForm}
                        isCurrentYear={true}
                        setFieldValue={setFieldValue}
                      />
                    </>
                  ) : null}
                </fieldset>
              </Form>
            </div>
            <div className="cb-modal-footer">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p className="cb-margin-bottom-16">
                  Before clicking <strong>Save</strong>, confirm that all information entered on
                  this form is accurate.
                </p>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <PrimaryButton
                    disabled={!isValid || isSubmitting || (!dirty && isStandAlone)}
                    loading={isSubmitting}
                    onClick={() => submitForm()}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </>
        )
      }}
    </Formik>
  )
}

export default RegistrationForm
