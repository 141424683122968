import { ApolloProvider } from '@apollo/client'
import { AppsyncClient, profile } from '@myap/ui-library'
import policies from '../../../appsync/policies'

export default ({ children }) => {
  const { myapUrl } = profile()
  const client = AppsyncClient(myapUrl, policies)

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
