import { PrimaryButton } from '@cb/apricot-react'
import styles from './actions.module.scss'

function InactiveButton({ content }) {
  return (
    <PrimaryButton
      className={styles['fake-disabled-button']}
      tabIndex="-1"
      disabled
      onClick={e => e.preventDefault()}
    >
      {content}
    </PrimaryButton>
  )
}

export default InactiveButton
