import orderBy from 'lodash/orderBy'
import { Icon, YellowButton, Popover } from '@cb/apricot-react'
import { profile } from '@myap/ui-library'
import ClassroomLink from '../../_common/actions/ClassroomLink'
import styles from './navigation.module.scss'

function APClassroomNavigation({ courses = [], hasAP, hasPreAP, preview = false }) {
  const triggerRef = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const { classroomUrl } = profile()
  // Don't include courses where AP Classroom is not available
  const filteredCourses = courses.filter(course => !course.disableAPClassroomLink)
  const numOfCourses = filteredCourses.length
  const isSingleCourse = numOfCourses === 1
  const linkTitle = `${hasAP ? 'AP' : ''}${hasAP && hasPreAP ? ' & ' : ''}${
    hasPreAP ? 'Pre-AP' : ''
  } Classroom`
  const classroomNavId = 'classroomNavBtn'

  // So we don't try to render Popover before the trigger node has a ref
  useEffect(() => {
    setLoaded(true)
  }, [])

  if (!numOfCourses) return null

  if (isSingleCourse) {
    return (
      <div className={styles['classroom-navigation']}>
        <ClassroomLink
          link={preview ? '#' : `${filteredCourses[0].testCd}/home`}
          className="cb-btn cb-btn-sm cb-btn-yellow"
          visuallyDisabled={false}
        >
          {linkTitle}
          <Icon name="east" style={{ marginLeft: '80px' }} />
        </ClassroomLink>
      </div>
    )
  }

  const items = orderBy(filteredCourses, 'courseName', 'asc').map(c => ({
    label: c.courseName,
    testCd: c.testCd,
  }))

  return (
    <div className={styles['classroom-navigation']}>
      <YellowButton id={classroomNavId} icon="down" ref={triggerRef}>
        {linkTitle}
      </YellowButton>
      {loaded ? (
        <Popover
          trigger={triggerRef.current}
          popoverId="classroom-menu"
          placement="bottom"
          label={`${linkTitle} Classroom`}
          popoverClassName={styles['classroom-popover']}
          shadowRoot
        >
          <ul className={styles['classroom-list']}>
            {items.map(({ label, testCd }) => (
              <li key={testCd}>
                <ClassroomLink link={preview ? '#' : `${testCd}/home`} visuallyDisabled={preview}>
                  {label}
                </ClassroomLink>
              </li>
            ))}
          </ul>
        </Popover>
      ) : null}
    </div>
  )
}

export default APClassroomNavigation
