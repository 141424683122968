import orderBy from 'lodash/orderBy'
import PreviewBanner from '../preview/banner/Banner'
import Banner from '../_common/banner/Banner'
import CourseCards from '../student/coursecards/CourseCards'
import Resources from '../student/resources/Resources'
import Timeline from '../student/timeline/Timeline'
import useUserSettingsQuery from '../../hooks/useUserSettingsQuery'
import { processJSON } from '../preview/utils'
import HorizontalLinkList from '../_common/horizontallinklist/HorizontalLinkList'
import styles from '../_common/banner/banner.module.scss'

import sample from '../preview/jsons/student.json'

const courseCardContainerId = 'previewScreen1'

function PreviewStudentApp() {
  const name = 'Student'
  // Get user details
  const {
    educationPeriod: { academicYrStartDate, academicYrEndDate },
  } = useUserSettingsQuery()

  const { enrollments, timeline } = processJSON(sample, academicYrStartDate, academicYrEndDate)

  return (
    <>
      <PreviewBanner name={name} />
      <Banner
        name={name}
        bannerId="previewScreen0"
        subHeader={<div className={styles['sub-header']}>Sample High School, 11th Grade</div>}
        navigation={
          <HorizontalLinkList items={[{ title: 'My AP Profile', href: '#' }]} withinBanner={true} />
        }
      />
      <Timeline enrollments={enrollments} events={timeline} anchorId={courseCardContainerId} />
      <div className="container cb-margin-top-48" id={courseCardContainerId} tabIndex="-1">
        <div className="row">
          <div className="col-xs-12">
            <h2 className="cb-h4 cb-margin-bottom-24">Courses &amp; Assignments</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <CourseCards courses={orderBy(enrollments, ['courseName'], ['asc'])} preview={true} />
          </div>
        </div>
        <Resources enrollments={enrollments} preview={true} />
      </div>
    </>
  )
}

export default PreviewStudentApp
