import { CMS_COORDINATOR } from '@myap/metadata'
import Dashboard from '../professional/Dashboard'
import useUserSettingsQuery from '../../hooks/useUserSettingsQuery'
import useCoordinatorCoursesQuery from '../professional/hooks/useCoordinatorCoursesQuery'
import useCoordinatorStatusQuery from '../professional/hooks/useCoordinatorStatusQuery'
import useTimeline from '../professional/hooks/useTimeline'
import { updateQueryProfessionalFineTune } from '../../appsync/actions/professional'

function ProfessionalApp() {
  const [needToCompletePartForm, setNeedToCompletePartForm] = useState(null)
  const [showOrderData, setShowOrderData] = useState(null)

  // Get user details
  const {
    userDetails: { selectedOrgId: orgId, roles },
    educationPeriod: { code: educationPeriod, isTransitionPeriod },
  } = useUserSettingsQuery()

  useEffect(() => {
    if (roles.length && orgId) {
      const role = roles.find(role => role.orgId === orgId)
      setNeedToCompletePartForm(role?.needToCompletePartForm)
      setShowOrderData(role?.showOrderData)
    }
  }, [roles.length, orgId])

  // Get coordinator status
  const status = useCoordinatorStatusQuery({
    orgId,
    educationPeriod,
  })

  // Get coordinator courses
  const courses = useCoordinatorCoursesQuery({
    orgId,
    educationPeriod,
  })

  const { events } = useTimeline({ courses: courses?.data })

  return (
    <Dashboard
      events={events}
      status={status}
      courses={courses}
      cmsRole={CMS_COORDINATOR}
      needToCompletePartForm={needToCompletePartForm}
      isTransitionPeriod={isTransitionPeriod}
      showOrderData={showOrderData}
    />
  )
}

export default ProfessionalApp
