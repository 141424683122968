import { COURSE_LABELS } from '@myap/metadata'
import useUserSettingsQuery from '../../../../hooks/useUserSettingsQuery'
import useSystemDatesLazyQuery from '../../../../hooks/useSystemDatesLazyQuery'
import { filterByCourseCategory, filterByTestCd } from '../utils'
import { EVENT_EXAM_DATE } from './constants'
import { getCMSTimelineEvents } from '../../../../rest/cms'
import partition from 'lodash/partition'

function Timeline({ userRole, courses, preloadedDates, component: Component }) {
  const [timelineEvents, setTimelineEvents] = useState([])

  // Get user details
  const {
    userDetails: { selectedOrgId: orgId, initialOrgId },
    systemDates,
    educationPeriod: { code: educationPeriod },
  } = useUserSettingsQuery()

  const [dates, setDates] = useState(preloadedDates || systemDates)
  const { getSystemDatesLazyQuery, data } = useSystemDatesLazyQuery()

  useEffect(() => {
    if (orgId !== initialOrgId) {
      getSystemDatesLazyQuery({ variables: { educationPeriod, orgId } })
    }
  }, [orgId])

  useEffect(() => {
    const fetch = async () => {
      const data = await getCMSTimelineEvents(userRole, educationPeriod)
      const filtered = filterByTestCd(courses, filterByCourseCategory(courses, data))
      const [systemDateEvents, otherEvents] = partition(filtered, ev => ev.systemDate || !ev.date)
      const processedOtherEvents = otherEvents.map(ev => {
        const dateArr = ev.date.split(' to ')
        return dateArr.length > 1 ? { ...ev, date: dateArr[0], endDate: dateArr[1] } : ev
      })

      const processedSystemEvents = systemDateEvents.reduce((acc, ev) => {
        const { deadlines = {}, examDecisions = {}, examDates = {} } = dates
        const isExamDate = ev.systemDate === EVENT_EXAM_DATE
        const examDecisionDeadline = examDecisions[ev.systemDate]
        const deadlineKey = Object.keys(deadlines).find(key => key === ev.systemDate)

        if (deadlineKey) return [...acc, { ...ev, date: deadlines[deadlineKey] }]

        if (examDecisionDeadline) {
          // exam decisions can be single date or array of dates (student)
          const decisions = Array.isArray(examDecisionDeadline)
            ? examDecisionDeadline.map(d => ({ ...ev, date: d }))
            : { ...ev, date: examDecisionDeadline }
          return [...acc, ...decisions]
        }

        if (isExamDate)
          return [
            ...acc,
            ...Object.keys(examDates).reduce((acc, testCd) => {
              const testCdInt = parseInt(testCd, 10)
              const courseName = COURSE_LABELS[testCdInt]
              return [
                ...acc,
                ...examDates[testCd].map(course => ({
                  ...ev,
                  title: `${courseName}: ${ev.title} ${
                    course.displayName ? `(${course.displayName})` : ''
                  }`,
                  date: course.examDate,
                  testCd: testCdInt,
                })),
              ]
            }, []),
          ]
        return acc
      }, [])
      setTimelineEvents([...processedSystemEvents, ...processedOtherEvents])
    }

    fetch()
  }, [dates])

  return <Component events={timelineEvents} dates={dates} />
}

export default Timeline
