import { BareModal } from '@cb/apricot-react'
import RegistrationForm from '../../profileandreg/RegistrationForm'
import { adjustModalHeight } from '../../../../utilities/dom'
import { useAnalyticsStudentRegStep2Event } from '../../../../hooks/analyticsHooks'
import { SAVE } from './constants'

const ID = 'studentRegistrationModal'

function StudentRegistrationModal({ changeStep }) {
  const [open, setOpen] = useState(true)
  const isStandAlone = typeof changeStep !== 'function'
  const useAnimation = true

  useAnalyticsStudentRegStep2Event()

  const adjustHeight = () => adjustModalHeight(`#${ID}`)

  return (
    <>
      <BareModal
        modalId={ID}
        open={open}
        openAnimation={useAnimation}
        closeAnimation={useAnimation}
        clickOverlayToClose={!isStandAlone}
        escClose={!isStandAlone}
        shadowRoot
        modalNode
      >
        <RegistrationForm
          changeStep={changeStep}
          nextStep={SAVE}
          setOpen={setOpen}
          isDismissable={!isStandAlone}
          adjustHeight={adjustHeight}
        />
      </BareModal>
    </>
  )
}

export default StudentRegistrationModal
