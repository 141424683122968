import { useApolloClient } from '@apollo/client'
import { USER_PREVIEW_STUDENT, USER_PREVIEW_TEACHER } from '@myap/metadata'
import { profile, StatusPanel, Spinner } from '@myap/ui-library'
import Banner from '../_common/banner/Banner'
import Courses from './courses/Courses'
import SchoolToggle from '../_common/schooltoggle/SchoolToggle'
import Resources from './resources/Resources'
import TeacherTable from '../_common/courseaccordion/TeacherTable'
import Notifications from '../_common/content/notifications/Notifications'
import HorizontalLinkList from '../_common/horizontallinklist/HorizontalLinkList'
import { getPreviewList } from '../preview/utils'
import ProfessionalTimeline from './timeline/Timeline'
import TimelineTeaser from '../_common/content/timeline/TimelineTeaser'

function Dashboard({
  events,
  status,
  courses,
  cmsRole,
  needToCompletePartForm,
  isTransitionPeriod,
  showOrderData,
}) {
  const client = useApolloClient()
  const { aproUrl } = profile()
  const anchorId = 'professionalCoursesAnchor'

  const previews = getPreviewList({
    client,
    previews: [USER_PREVIEW_TEACHER, USER_PREVIEW_STUDENT],
  })

  return (
    <>
      <Banner
        subHeader={<SchoolToggle />}
        navigation={<HorizontalLinkList items={previews} withinBanner={true} />}
      />
      <TimelineTeaser events={events} />
      <Notifications courses={courses?.data} userRole={cmsRole} showAsDropdown={false} />
      <div className="container">
        {!needToCompletePartForm && !isTransitionPeriod ? (
          <div className="row">
            <div className="col-xs-12">
              <h2 id={anchorId} className="cb-h4 cb-margin-top-48 cb-margin-bottom-16">
                <a href={`${aproUrl}/dashboard`}>AP Registration and Ordering</a>
              </h2>
            </div>
          </div>
        ) : null}
        {!needToCompletePartForm && showOrderData ? (
          <div className="row">
            <div className="col-xs-12">
              <StatusPanel
                data={status?.data}
                loading={status?.loading}
                error={status?.error}
                history={null}
              />
            </div>
          </div>
        ) : null}
        {!needToCompletePartForm ? (
          <div className="row">
            <div className="col-xs-12">
              {courses?.loading ? (
                <Spinner spinnerId="appSpinner" size="64" />
              ) : (
                <Courses courses={courses?.data} body={TeacherTable} />
              )}
            </div>
          </div>
        ) : null}
        {courses?.loading ? (
          <Spinner spinnerId="appSpinner" size="64" />
        ) : (
          <Resources
            courses={courses?.data}
            userRole={cmsRole}
            showTransitionContent={needToCompletePartForm && isTransitionPeriod}
          />
        )}
        <ProfessionalTimeline
          events={events}
          anchorId={anchorId}
          anchorTitle="Ap Registration and Ordering"
        />
      </div>
    </>
  )
}

export default Dashboard
