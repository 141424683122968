import EnterCode from './EnterCode'
import ConfirmCode from './ConfirmCode'
import ExitMessage from './ExitMessage'
import Survey from './Survey'
import Profile from './Profile'
import Register from './Register'
import Save from './Save'
import useUserSettingsQuery from '../../../../hooks/useUserSettingsQuery'
import useStudentEnrollmentsQuery from '../../hooks/useStudentEnrollmentsQuery'
import * as c from './constants'

export default React.memo(props => {
  // Get user details
  const {
    educationPeriod: { code: educationPeriod, hasEnrollments },
  } = useUserSettingsQuery()

  // Get student enrollments
  const { data: enrollmentsData } = useStudentEnrollmentsQuery({
    educationPeriod,
    otherSkipConditions: !hasEnrollments,
  })
  const [step, updateStep] = useState(c.ENTER)
  let Component

  // add check to make sure step value is only updated if the new value exists and is different from the previous value
  // this may not be needed, but we need to try and prevent unnecessary re-renderings
  const changeStep = newStep => {
    if (newStep && newStep !== step) {
      updateStep(newStep)
    }
  }

  switch (step) {
    case c.ENTER:
      Component = EnterCode
      break
    case c.CONFIRM:
      Component = ConfirmCode
      break
    case c.EXIT:
      Component = ExitMessage
      break
    case c.SURVEY:
      Component = Survey
      break
    case c.INFORMATION:
      Component = Profile
      break
    case c.REGISTER:
      Component = Register
      break
    case c.SAVE:
      Component = Save
      break
    default:
      return null
  }

  return (
    <Component
      {...props}
      enrollments={enrollmentsData?.courseEnrollments}
      changeStep={changeStep}
    />
  )
})
