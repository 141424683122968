import { profile } from '@myap/ui-library'
import ToggleInactiveLink from './ToggleInactiveLink'

function ClassroomLink({
  link,
  title = 'AP Classroom',
  children,
  ariaId,
  className,
  visuallyDisabled = true,
}) {
  return (
    <ToggleInactiveLink
      href={`${profile().classroomUrl}/${link || ''}`}
      inactive={link === '#'}
      ariaId={ariaId}
      className={className}
      visuallyDisabled={visuallyDisabled}
    >
      {children || title}
    </ToggleInactiveLink>
  )
}

export default ClassroomLink
