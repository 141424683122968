import { ErrorNotification } from '@cb/apricot-react'
import { useApolloClient } from '@apollo/client'
import {
  USER_ADMIN,
  USER_COORDINATOR,
  USER_STUDENT,
  USER_TEACHER,
  USER_DISTRICT_ADMIN,
  USER_DISTRICT_FUNDING_ADMIN,
  USER_DISTRICT_SUPER_ADMIN,
  USER_PREVIEW_STUDENT,
  USER_PREVIEW_TEACHER,
  isCoordinator,
  isUnknown,
  isWidgetUser,
  isDistrictFundingAdmin,
  isDistrictSuperAdmin,
} from '@myap/metadata'
import { profile, getSelectedOrgId, Spinner, isDateSameOrAfter } from '@myap/ui-library'
import { isNewLoggedInSession, setSelectedOrgId } from '@myap/ui-library/cjs/cookies'
import Provider from '../_common/apollo/Provider'
import StudentApp from './StudentApp'
import TeacherApp from './TeacherApp'
import ProfessionalApp from './ProfessionalApp'
import AdminApp from './AdminApp'
import DistrictApp from './DistrictApp'
import UnknownApp from './UnknownApp'
import PreviewStudentApp from './PreviewStudentApp'
import PreviewTeacherApp from './PreviewTeacherApp'
import useUserSettingsQuery from '../../hooks/useUserSettingsQuery'
import { setOrg, setRole } from '../../appsync/actions/settings'

const getRedirectUrlAndSetReferrer = ({ userDetails, educationPeriod, systemDates }) => {
  const { acceptedGeneralTAC, acceptedFundingTAC, selectedRole, roles, activeRoleCd } =
    userDetails ?? {}
  const { isTransitionPeriod } = educationPeriod ?? {}
  const needToCompletePartForm = roles?.find(r => r.needToCompletePartForm)
  const { aproUrl } = profile()
  const isUnknownUser = isUnknown(selectedRole)
  const isCoordUser = isCoordinator(selectedRole)
  const isQualifiedDistrictUser =
    isDistrictFundingAdmin(selectedRole) || isDistrictSuperAdmin(selectedRole)

  const roleArr = []
  let roleTypes = []
  let hasMultipleRoles = false
  if (!activeRoleCd) {
    roles.forEach(({ role }) => roleArr.push(role))
    roleTypes = [...new Set(roleArr)] // Produces a set of unique role codes
    hasMultipleRoles = roleTypes.length > 1
  }

  if (!activeRoleCd && hasMultipleRoles) {
    return `${aproUrl}/selectrole`
  }

  const today = new Date()
  const isAfterDistrictFundingStartdate = systemDates?.deadlines?.districtFundingStartDate
    ? isDateSameOrAfter(today, systemDates?.deadlines?.districtFundingStartDate)
    : false

  if (!isWidgetUser(selectedRole)) {
    return `${aproUrl}/dashboard`
  }

  // Redirect to Terms & Conditions if general is not accepted or if user is District Funding or
  // District Super Admin and funding has not been accepted and it is after district funding start date
  if (
    (!acceptedGeneralTAC && !isUnknownUser) ||
    (isQualifiedDistrictUser && !acceptedFundingTAC && isAfterDistrictFundingStartdate)
  ) {
    return `${aproUrl}/termsandconditions`
  }

  if (needToCompletePartForm && isCoordUser && !isTransitionPeriod) {
    return `${aproUrl}/setup`
  }

  return null
}

function MainApp({ userDetails, educationPeriods, systemDates }) {
  const client = useApolloClient()
  const districtOrg = userDetails?.roles?.find(({ districtOrg }) => districtOrg === true)
  if (districtOrg?.orgId && isNewLoggedInSession()) {
    setSelectedOrgId(districtOrg.orgId)
  }
  // educationPeriods can be null when user is Unknown professional
  const educationPeriod =
    (Array.isArray(educationPeriods)
      ? educationPeriods.length === 1
        ? educationPeriods[0]
        : educationPeriods.find(edpd => edpd.chronology === 'current')
      : {}) ?? {}
  const { selectedRole, initialOrgId, roles } = userDetails ?? {}
  const preselectedOrgId = getSelectedOrgId()
  const redirect = getRedirectUrlAndSetReferrer({ userDetails, educationPeriod, systemDates })

  if (redirect) {
    window.location = redirect
    return <Spinner spinnerId="appSpinner" size="64" />
  }

  useEffect(() => {
    // load previously selected org (for users with multiple org associations)
    const { orgId, role } =
      (preselectedOrgId && roles.find(r => r.orgId === preselectedOrgId)) || {}
    if (orgId && orgId !== initialOrgId) {
      setOrg(client, orgId)
      setRole(client, role)
    }
  }, [preselectedOrgId, roles, initialOrgId])

  console.log('Starting MainApp with role:', selectedRole)

  switch (selectedRole) {
    case USER_STUDENT:
      return <StudentApp />
    case USER_TEACHER:
      return <TeacherApp />
    case USER_DISTRICT_ADMIN:
    case USER_DISTRICT_FUNDING_ADMIN:
    case USER_DISTRICT_SUPER_ADMIN:
      return <DistrictApp userRole={selectedRole} />
    case USER_COORDINATOR:
      return <ProfessionalApp />
    case USER_ADMIN:
      return <AdminApp />
    case USER_PREVIEW_STUDENT:
      return <PreviewStudentApp />
    case USER_PREVIEW_TEACHER:
      return <PreviewTeacherApp />
    default:
      return <UnknownApp />
  }
}

function App() {
  const { userDetails, educationPeriods, systemDates, loading, error } = useUserSettingsQuery()

  if (error) {
    return <ErrorNotification>{error}</ErrorNotification>
  }

  if (loading) {
    return <Spinner spinnerId="appSpinner" size="64" />
  }

  return (
    <MainApp
      userDetails={userDetails}
      educationPeriods={educationPeriods}
      systemDates={systemDates}
    />
  )
}

export default () => (
  <Provider>
    <div id="myap-root">
      <App />
    </div>
  </Provider>
)
