import { profile, formatDate, isDateSameOrBefore, isDateBefore } from '@myap/ui-library'
import DistrictInvoice from './DistrictInvoice'
import useUserSettingsQuery from '../../../hooks/useUserSettingsQuery'
import useDistrictFundingQuery from '../hooks/useDistrictFundingQuery'
import Flag from '../../_common/featureflag/Flag'

export default function DistrictFunding() {
  // Get user details
  const {
    userDetails: { roles, selectedOrgId: orgId },
    educationPeriod: { code: educationPeriod, descr: academicYearDescr },
    systemDates,
  } = useUserSettingsQuery()

  // Get district funding
  const {
    data: districtFundingData,
    error: districtFundingError,
    loading: districtFundingLoading,
  } = useDistrictFundingQuery({ orgId, educationPeriod })

  const { aproUrl } = profile()
  const today = new Date()
  const districtFundingLockDate = systemDates?.deadlines?.districtFundingLockDate
  const districtFundingStartDate = systemDates?.deadlines?.districtFundingStartDate
  const isBeforeStartDate =
    districtFundingStartDate && isDateBefore(today, districtFundingStartDate)
  const roleForOrg = roles.find(role => role.orgId === orgId)
  const dfStyles = { marginBottom: 0 }

  if (districtFundingLoading) {
    return (
      <span
        className="cb-loader-shimmer cb-loader-effect cb-margin-top-24"
        style={{ display: 'inline-block', width: '540px', height: '40px' }}
      />
    )
  }

  // Only display District Funding component if a district is the currently selected org
  if (roleForOrg.districtOrg) {
    // Current date is before the District Funding Lock Date
    // OR a funding plan has been saved
    if (
      (districtFundingLockDate && isDateSameOrBefore(today, districtFundingLockDate)) ||
      districtFundingData?.grantor?.grantorName
    ) {
      let message = null
      // Current date is before the District Funding Lock Date
      // AND no funding plan has been saved
      if (
        districtFundingLockDate &&
        isDateSameOrBefore(today, districtFundingLockDate) &&
        !districtFundingData?.grantor?.grantorName
      ) {
        message = (
          <p style={dfStyles}>
            Is your district providing funding for AP Exams?{' '}
            {isBeforeStartDate ? (
              <>
                You can set up and specify your district&#39;s funding plan starting{' '}
                {formatDate(districtFundingStartDate, 'MMMM d')}
              </>
            ) : (
              <>
                <a href={`${aproUrl}/funding`}>
                  Set up and specify your district&#39;s funding plan
                </a>{' '}
                by {formatDate(districtFundingLockDate, 'MMMM d')}
              </>
            )}
            .
          </p>
        )
        // Current date is after District Funding Lock Date
        // AND funding plan has been saved
      } else if (districtFundingData?.grantor?.grantorName) {
        message = (
          <p style={dfStyles}>
            You have completed your district funding plan for {academicYearDescr}.{' '}
            <a href={`${aproUrl}/funding`}>View Funding Plan</a>.
          </p>
        )
      }
      // Current date is before the District Funding Lock Date
      // AND no funding plan has been saved
      return (
        <Flag feature="DISTRICT_FUNDING">
          <div className="row">
            <div className="col-xs-12">
              <h2 id="districtFunding" className="cb-h5 cb-margin-top-48 cb-margin-bottom-24">
                {isBeforeStartDate ? (
                  <>AP District Funding and Billing</>
                ) : (
                  <a href={`${aproUrl}/funding`}>AP District Funding and Billing</a>
                )}
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">{message}</div>
          </div>
          <DistrictInvoice />
        </Flag>
      )
    }
    // Current date is after District Funding Lock Date
    // AND no funding plan has been saved
    return (
      <Flag feature="DISTRICT_FUNDING">
        <div className="row">
          <div className="col-xs-12">
            <h2 id="districtFunding" className="cb-h5 cb-margin-top-48 cb-margin-bottom-24">
              AP District Funding and Billing
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <p>
              District Funding lock date {formatDate(districtFundingLockDate, 'MMMM d')} has passed.
            </p>
          </div>
        </div>
        <DistrictInvoice />
      </Flag>
    )
  }
  return null
}
